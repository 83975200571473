<template>
  <div>
    <CyHeader v-if="environment">
      <template #title>
        <router-link :to="{ name: 'project', params: { projectCanonical } }">
          {{ projectName }}
        </router-link>
        <span
          class="separator"
          v-text="'/'"/>
        <CyEnvironmentComponentQuickSwitch
          ref="envQuickSwitch"
          :current-env="currentEnv"
          :current-env-component="currentEnvComponent"
          :envs="envs"
          scope="environment"
          @select:environment="handleSelectEnvironment"
          @select:component="handleSelectComponent"
          @add:environment="handleAddEnvironment"
          @add:component="handleAddComponent"/>
        <span
          class="separator"
          v-text="'/'"/>
        <CyEnvironmentComponentQuickSwitch
          v-if="currentEnvComponent"
          ref="componentQuickSwitch"
          :current-env="currentEnv"
          :current-env-component="currentEnvComponent"
          :envs="envs"
          scope="envComponent"
          @select:environment="handleSelectEnvironment"
          @select:component="handleSelectComponent"
          @add:environment="handleAddEnvironment"
          @add:component="handleAddComponent"/>
      </template>
      <template #actions>
        <CyMenu
          v-if="!_.$isEmpty($static.envComponentActions)"
          class="cy-project-card-header__menu"
          offset-y
          left
          :items="$static.envComponentActions"/>
      </template>
      <template #metadata>
        <v-menu
          v-if="currentEnvComponent"
          open-on-hover
          open-delay="200"
          close-delay="200"
          offset-y>
          <template #activator="{ on }">
            <CyHeaderMetadata
              icon="fa-cubes"
              :to="{ name: 'stack', params: { stackRef: stack.ref } }"
              :label="`${stack.canonical}#${currentEnvComponent.use_case}`"
              v-on="on"/>
          </template>
          <CyWizardServiceCard
            class="border-none"
            hide-more-btn
            :max-width="440"
            :service="stack"/>
        </v-menu>
        <CyHeaderMetadata
          v-if="currentEnvComponent && currentEnvComponent.created_at"
          :label="getCreatedLabel(currentEnvComponent)"/>
        <CyHeaderMetadata
          v-if="currentEnvComponent && currentEnvComponent.updated_at"
          :label="getUpdatedLabel(currentEnvComponent)"/>
      </template>
    </CyHeader>

    <CyModal
      v-if="showDeleteModal"
      :header-title="$t('environment.component.delete')"
      :loading="deletingEnvComponent"
      :action-btn-func="onDeleteComponent"
      :cancel-btn-func="() => { showDeleteModal = false }"
      :action-btn-text="$t('forms.btnDelete')"
      keep-open-on-action-click
      modal-type="delete"
      small>
      <div class="pb-5">
        <p class="font-weight-bold">
          {{ $t('environment.component.deleteInfo') }}
        </p>
        <div class="d-flex align-center">
          <p class="ma-0 mr-2">
            {{ $t('environment.component.deleteConfirm') }}
          </p>
          <span
            v-if="currentEnvComponent && currentEnvComponent.service_catalog"
            class="d-flex align-center">
            <CyStackAvatar
              :stack="currentEnvComponent.service_catalog"
              :size="24"/>
            <div class="font-size-base">{{ currentEnvComponent.name }}</div>
          </span>
        </div>
      </div>
    </CyModal>

    <v-slide-x-reverse-transition>
      <portal
        v-if="showEnvAddModal"
        to="side-panel">
        <CyEnvironmentAddModal
          @close="$toggle.showEnvAddModal"/>
      </portal>
    </v-slide-x-reverse-transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CyEnvironmentAddModal from '@/components/CyEnvironmentAddModal.vue'
import CyEnvironmentComponentQuickSwitch from '@/components/CyEnvironmentComponentQuickSwitch.vue'
import CyHeader from '@/components/CyHeader.vue'
import CyHeaderMetadata from '@/components/CyHeaderMetadata.vue'
import CyStackAvatar from '@/components/CyStackAvatar.vue'
import CyWizardServiceCard from '@/components/CyWizardServiceCard.vue'
import { getCreatedLabel, getUpdatedLabel } from '@/utils/helpers'

export default {
  name: 'CyHeaderVariantEnvComponent',
  components: {
    CyEnvironmentAddModal,
    CyEnvironmentComponentQuickSwitch,
    CyHeader,
    CyHeaderMetadata,
    CyWizardServiceCard,
    CyStackAvatar,
  },
  data: () => ({
    environment: null,
    showEnvAddModal: false,
    showDeleteModal: false,
    deletingEnvComponent: false,
  }),
  computed: {
    ...mapGetters('organization/project', [
      'envs',
    ]),
    $static () {
      const { canDisplay } = this.$cycloid.permissions
      return {
        envComponentActions: [
          {
            icon: 'settings',
            label: `${this.$t('environment.component.settings')}...`,
            action: () => {
              this.$router.push({
                name: 'envComponentSettings',
                params: { backRouteTo: this.$route.name },
              })
            },
            permissionKey: 'UpdateProject',
          },
          {
            divider: true,
          },
          {
            icon: 'delete',
            label: `${this.$t('environment.component.delete')}...`,
            color: 'error',
            action: () => { this.showDeleteModal = true },
            permissionKey: 'UpdateProject',
          },
        ].filter(({ permissionKey }) => !permissionKey || canDisplay(permissionKey, this.projectCanonical)),
      }
    },
    projectCanonical () {
      return this.$route?.params?.projectCanonical
    },
    envCanonical () {
      return this.$route?.params?.envCanonical
    },
    envComponentCanonical () {
      return this.$route?.params?.componentCanonical
    },
    currentEnv () {
      return _.find(this.envs, ['canonical', this.envCanonical])
    },
    currentEnvComponent () {
      const components = _.get(this.currentEnv, 'components', [])
      return _.find(components, ['canonical', this.envComponentCanonical]) || null
    },
    stack () {
      return _.get(this.currentEnvComponent, 'service_catalog')
    },
  },
  watch: {
    envs: {
      handler (values) {
        this.environment = _.find(values, ['canonical', this.envCanonical])
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('organization/project', [
      'DELETE_ENV_COMPONENT',
      'GET_PROJECT_ENVS',
    ]),
    getCreatedLabel,
    getUpdatedLabel,
    async onDeleteComponent () {
      const { envCanonical } = this
      this.deletingEnvComponent = true
      await this.DELETE_ENV_COMPONENT({
        envCanonical,
        componentCanonical: this.currentEnvComponent.canonical,
      })
      this.GET_PROJECT_ENVS()
      this.$router.push({
        name: 'environmentOverview',
        params: {
          projectCanonical: this.projectCanonical,
          envCanonical,
        },
      })
    },
    handleSelectEnvironment (envCanonical) {
      this.$router.push({
        name: 'environmentOverview',
        params: {
          projectCanonical: this.projectCanonical,
          envCanonical,
        },
      })
    },
    handleAddEnvironment () {
      this.showEnvAddModal = true
      this.$refs.envQuickSwitch.closeMenu()
      this.$refs.componentQuickSwitch.closeMenu()
    },
    handleSelectComponent (envCanonical, componentCanonical) {
      this.$router.replace({
        name: this.$route.name,
        params: {
          projectCanonical: this.projectCanonical,
          envCanonical,
          componentCanonical,
        },
      })
    },
    handleAddComponent (envCanonical) {
      this.$router.push({
        name: 'newEnvComponent',
        params: {
          projectCanonical: this.projectCanonical,
          envCanonical,
        },
      })
      this.$refs.envQuickSwitch.closeMenu()
      this.$refs.componentQuickSwitch.closeMenu()
    },
  },
}
</script>

<style lang="scss" scoped>
.separator {
  display: flex;
  justify-content: center;
  width: 25px;
  color: cy-get-color("primary", "light-3");
}

.router-link-active {
  color: cy-get-color("primary", "dark-1") !important;
}
</style>
